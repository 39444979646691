/* eslint-disable react/jsx-props-no-spreading */
import React, {
  ReactNode,
  memo,
  useRef,
  useEffect,
  useState,
  useMemo,
} from 'react';
import ReactSelect, {
  ActionMeta,
  GroupBase,
  MenuPlacement,
  PropsValue,
  SingleValue,
} from 'react-select';
import { Tooltip } from 'react-tooltip';
import scssStyles from './select.module.scss';
import getCustomStyles from './customStyles';

// Create a singleton context for string overflow measurement
class StringOverflowMeasurer {
  private static instance: StringOverflowMeasurer;

  private context: CanvasRenderingContext2D;

  private constructor() {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) {
      throw new Error('Cannot create canvas context');
    }

    this.context = context;
  }

  public static getInstance(): StringOverflowMeasurer {
    if (!StringOverflowMeasurer.instance) {
      StringOverflowMeasurer.instance = new StringOverflowMeasurer();
    }
    return StringOverflowMeasurer.instance;
  }

  public isStringOverflow(
    divWidth: number,
    text: string,
    fontSize = 14,
    fontFamily = 'Inter',
  ): boolean {
    this.context.font = `${fontSize}px ${fontFamily}`;
    const textWidth = this.context.measureText(text).width;
    return textWidth > divWidth;
  }
}

export interface SelectOption {
  value: string;
  label: string;
  parentLabel?: string;
  parentValue?: string;
}

export interface GroupedSelectOption {
  label: string;
  value: string;
  options: SelectOption[];
}

function NoOptionsMessage(message: string) {
  return <p className={scssStyles.noOptions}>{message}</p>;
}

const formatGroupLabel = (data: GroupBase<SelectOption>) => (
  <div className={scssStyles.groupLabel}>
    <span>{data.label}</span>
  </div>
);

interface SelectProps {
  options: Array<SelectOption | GroupedSelectOption>;
  label?: ReactNode;
  defaultValue?: SelectOption;
  name?: string;
  id?: string;
  value?: PropsValue<SelectOption>;
  handleChange: (
    option: SingleValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>,
    parent?: { label: string; value: string },
  ) => void;
  placeholder?: string;
  error?: boolean;
  message?: string;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  showOptionalLabel?: boolean;
  icon?: JSX.Element;
  menuPortalTarget?: HTMLElement;
  highlightOnError?: boolean;
  styles?: object;
  noOptionsMessage?: string;
  menuPlacement?: MenuPlacement;
}

function Select({
  options,
  defaultValue,
  label,
  name,
  id,
  value,
  handleChange,
  placeholder,
  error,
  message,
  className,
  containerClassName,
  disabled,
  showOptionalLabel,
  icon,
  menuPortalTarget,
  highlightOnError,
  styles,
  noOptionsMessage,
  menuPlacement,
  ...props
}: SelectProps) {
  const selectContainerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [showOverflowTooltip, setShowOverflowTooltip] = useState(false);

  // Memoize custom styles to prevent unnecessary re-renders
  const customStyles = useMemo(
    () => getCustomStyles(!!icon, highlightOnError && error && message !== undefined),
    [icon, highlightOnError, error, message],
  );

  // Memoize options transformation to prevent unnecessary re-computations
  const modifiedOptions = useMemo(
    () => options?.map((option) => {
      if ('options' in option) {
        const group = option as GroupedSelectOption;
        return {
          ...group,
          options: group.options?.map((opt) => ({
            ...opt,
            parentLabel: group.label,
            parentValue: group.value,
          })),
        };
      }
      return option;
    }),
    [options],
  );

  // Measure container width and check for overflow
  useEffect(() => {
    const measureContainer = () => {
      if (selectContainerRef.current) {
        const width = selectContainerRef.current.offsetWidth;
        setContainerWidth(width);
      }
    };

    // Measure initially
    measureContainer();

    // Remeasure on window resize
    window.addEventListener('resize', measureContainer);

    return () => {
      window.removeEventListener('resize', measureContainer);
    };
  }, []);

  // Check overflow when value or container width changes
  useEffect(() => {
    if (value && typeof value === 'object' && 'label' in value) {
      const overflowDetected = StringOverflowMeasurer.getInstance().isStringOverflow(
        containerWidth * 0.6, // Use 60% of container width
        value.label || '',
      );
      setShowOverflowTooltip(overflowDetected);
    } else {
      setShowOverflowTooltip(false);
    }
  }, [value, containerWidth]);

  return (
    <>
      <div
        ref={selectContainerRef}
        className={`${scssStyles.selectContainer} ${containerClassName}`}
      >
        {label && (
          <label htmlFor={id || name}>
            {label}
            {showOptionalLabel && <span>(optional)</span>}
          </label>
        )}
        <div className={scssStyles.iconWrapper}>{icon}</div>
        <div data-tooltip-id={`select-tooltip-${name}-${id}-${placeholder}`}>
          <ReactSelect
            className={`${className}`}
            placeholder={placeholder}
            components={{
              IndicatorSeparator: () => null,
              NoOptionsMessage: () => NoOptionsMessage(noOptionsMessage || 'No options'),
            }}
            defaultValue={defaultValue}
            options={modifiedOptions}
            value={value}
            menuPlacement={menuPlacement}
            styles={{ ...customStyles, ...styles }}
            onChange={(selectedOption, actionMeta) => {
              const parentInfo =
                selectedOption && 'parentLabel' in selectedOption && 'parentValue' in selectedOption
                  ? {
                    label: (selectedOption as SelectOption).parentLabel!,
                    value: (selectedOption as SelectOption).parentValue!,
                  }
                  : undefined;
              handleChange(selectedOption, actionMeta, parentInfo);
            }}
            menuShouldScrollIntoView={false}
            menuPortalTarget={menuPortalTarget}
            isMulti={false}
            isDisabled={disabled}
            formatGroupLabel={formatGroupLabel}
            {...props}
          />
        </div>
        {error && message && (
          <span
            className={`input-hint-msg ${error ? 'error-msg' : ''} ${message ? 'show' : 'hide'}`}
          >
            {message}
          </span>
        )}
      </div>
      {showOverflowTooltip && (
        <Tooltip
          id={`select-tooltip-${name}-${id}-${placeholder}`}
          className={scssStyles.tooltipStyles}
          positionStrategy="fixed"
        >
          {(value as SelectOption)?.label}
        </Tooltip>
      )}
    </>
  );
}

Select.defaultProps = {
  id: undefined,
  name: undefined,
  label: '',
  defaultValue: undefined,
  placeholder: 'Select...',
  value: undefined,
  error: false,
  message: undefined,
  className: '',
  containerClassName: '',
  disabled: false,
  showOptionalLabel: false,
  icon: undefined,
  menuPortalTarget: null,
  highlightOnError: false,
  styles: {},
  noOptionsMessage: 'No options',
  menuPlacement: 'bottom',
};

export default memo(Select, (prevProps, nextProps) => {
  // Custom comparison to prevent unnecessary re-renders
  const keys: Array<keyof SelectProps> = [
    'options', 'label', 'defaultValue', 'name', 'id', 'value',
    'placeholder', 'error', 'message', 'className', 'containerClassName',
    'disabled', 'icon', 'styles', 'noOptionsMessage', 'menuPlacement',
  ];

  return keys.every((key) => prevProps[key] === nextProps[key]);
});
