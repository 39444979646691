import moment from 'moment-timezone';

export const convertDate =
(date: string | Date, format: string): string => moment(date).parseZone().format(format);

export const getTimeValueFromDate = (time: string): number => {
  // Check if the string contains a "T" character indicating ISO format
  const isISOFormat = time.includes('T') && time.endsWith('Z');
  const format = isISOFormat ? undefined : 'MMM D, YYYY h:mm:ss A Z';

  return moment(time, format).valueOf();
};

export const capitalizeFirstLetter = (str: string) => {
  const words = str?.split(' ');

  const capitalizedWords = words?.map((word) => {
    if (word.length > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    return word;
  });

  return capitalizedWords?.join(' ');
};

export const capitalizeFirstLetterOfString = (str: string): string => {
  if (!str) return str;

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeInput = (e: string) => {
  const words = e.split('');
  const capitalizedWords = words
    .map((ele) => {
      if (ele) {
        return ele ? ele[0].toUpperCase() + ele.substring(1) : '';
      }
      return ele;
    })
    .join('');

  return capitalizedWords;
};

export const formatTimeDifferenceFromCurrentTime = (targetDate: Date) => {
  const now = moment();
  const difference = moment.duration(now.diff(moment(targetDate)));
  const daysDifference = difference.asDays();

  if (daysDifference < 1) {
    return `Down by ${Math.floor(difference.asHours())} hours`;
  }
  return `Down by ${Math.floor(daysDifference)} ${Math.floor(daysDifference) > 1 ? 'days' : 'day'}`;
};

export function getTimeDifference(startTime: string, endTime: string) {
  const start = moment(startTime, ['h:mm A', moment.ISO_8601], true);
  const end = moment(endTime, ['h:mm A', moment.ISO_8601], true);

  if (!start.isValid() || !end.isValid()) {
    throw new Error('Invalid time format');
  }

  // If end time is before start time, add 1 day to end time
  if (end.isBefore(start)) {
    end.add(1, 'day');
  }

  const diffMs = end.diff(start);
  const duration = moment.duration(diffMs);

  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return { days, hours, minutes, seconds };
}

export const formatTimestamp = (timestamp: string, timestampFormat: string) => {
  const date = moment(timestamp);
  return date.isValid() ? moment(timestamp).parseZone().format(timestampFormat) : '';
};
