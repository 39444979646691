/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import './styles.scss';
import PageNotFound from 'Common/Pages/PageNotFound';
import NotificationContainer from 'Common/Components/Notification';
import useAuthSync from 'Common/Hooks/useAuthSync';
import GlobalLoader from './Components/GlobalLoader';
import AppLayout from './Components/AppLayout';
import { authRoutes, unAuthRoutes } from './routes';

function AuthRoutes() {
  return (
    <AppLayout>
      <React.Suspense fallback={<GlobalLoader />}>
        <Routes>
          {authRoutes.map(({ path, element }) => (
            <Route key={path} index path={path} element={element} />
          ))}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </React.Suspense>

    </AppLayout>
  );
}

function Portal() {
  useAuthSync();

  return (
    <div className="wobot-partner-portal">
      <NotificationContainer />
      <GlobalLoader />
      <React.Suspense fallback={<GlobalLoader />}>
        <Routes>
          {unAuthRoutes.map(({ path, element }) => (
            <Route index key={path} path={path} element={element} />
          ))}
          <Route path="*" element={<AuthRoutes />} />
        </Routes>
      </React.Suspense>
    </div>
  );
}

export default Portal;
